<template>
  <loader v-bind="{ loading }" text="Loading Equipment">
    <columns>
      <column class="is-narrow">
        <action-button v-if="!$root.isPassiveUser()" @click="openImporter" class="is-small is-rounded">
          Import Equipment
        </action-button>
        <action-button 
          :working="downloading"
          @click="downloadXls"
          class="is-small is-rounded">
          Download XLSX
        </action-button>
      </column>
      <column>
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search for equipment...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="$route.query.search"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>
    <columns>
      <column>
        <div class="box" v-for="equipment in equipment.data" :key="equipment.id">
          <columns>
            <column>
              <h4 class="is-size-5 has-text-weight-bold">
                <router-link :to="{
                  name: 'equipment-manager',
                  params: {
                      equipment: equipment.uuid
                  }}">
                  {{ equipment.name }}
                </router-link>
              </h4>
            </column>
            <column class="is-3 is-flex is-align-items-center">
              <icon icon="qrcode"/>
              {{ equipment.code.value }}
            </column>
            <column class="is-narrow">
                <span class="tag is-rounded">{{ equipment.status }}</span>
            </column>
          </columns>
        </div>
        <pager v-if="equipment.data.length" :pageable="equipment" context="Equipment" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="equipment.data.length"
          heading="There is no equipment available for display."
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { common as backend } from '@/api'
import { importEquipment } from '@/modals'
import { mapGetters } from 'vuex'
import download from 'downloadjs'

export default {

  data: () => ({
    loading: true,
    downloading: false,
    filters: {
        search: ''
    },
    equipment: {
      data: []
    }
  }),

  created() {
    if(this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadEquipments()
  },

  methods: {
    loadEquipments() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.equipment = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'location-equipment',
        params: {
            location: this.$route.params.location
        },
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        params: {
            location: this.$route.params.location
        },
        name: 'location-equipment',
      });
    },
    openImporter() {
      importEquipment(this.location)
    },
    downloadXls() {
      this.downloading = true
      backend.downloadFile(`/v1/locations/${this.location.uuid}/equipment-xls`, ({data}) => {
        this.downloading = false
        download(data, `${this.location.name}-equipment-list.xlsx`, data.type);
      }, () => this.downloading = false, this.filters)
    }
  },

  computed: {
    ...mapGetters('location', [
      'location'
    ])
  },

  watch: {
    '$route': 'loadEquipments'
  }

}
</script>